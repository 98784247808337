import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
// import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import ModulesList from '../../../admin/responsibility/searchModule';
import customerDetails from './customerDetails';
import tenantLease from '../../../leaseAdmin/lease/leases/tenantLease/addEditTenantLease';
import {
  minLength,
  maxLength,
  email,
  required
} from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../../store';
export default {
  name: 'addCustomerQueries',
  components: {
    DatePicker,
    // party,
    ModelSelect,
    ModulesList,
    store,
    customerDetails,
    tenantLease
  },
  validations: {
    contactNum: {
      maxLength: maxLength(10),
      minLength: minLength(10),
      required
    },
    addContactNumber: {
      maxLength: maxLength(10),
      minLength: minLength(10)
    },
    email: { email, required }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      unsubscribe: null,
      editMode: false,
      parent_value_set_id: null,
      depVsetParam: null,
      showValueSetModal: false,
      showPartyModal: false,
      showModal: false,
      tab: null,
      batchId: 0,
      entityId: null,
      queryId: 0,
      queryNum: null,
      legalEntity: {
        value: null,
        text: null
      },
      selectedModule: {
        name: null,
        id: null
      },
      entityType: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floor: {
        value: null,
        text: null
      },
      unit: {
        value: null,
        text: null
      },
      category: {
        value: null,
        text: null
      },
      subCategory: {
        value: null,
        text: null
      },
      subSubCategory: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      priority: {
        value: null,
        text: null
      },
      status: {
        value: 'New',
        text: 'New'
      },
      email: null,
      contactNum: null,
      raisedBy: {
        value: null,
        text: null
      },
      // party: {
      //   value: null,
      //   text: null
      // },
      // partyType: null,
      appointmentDate: null,
      fromTimeToTime: [],
      tat: null,
      tatUom: {
        value: null,
        text: null
      },
      subCategoryId: null,
      addContactNumber: null,
      comments: null,
      defaultValue: { value: null, text: null },
      queryFlag: false,
      showCustomerModal: false,
      custFlag: false,
      custLeaseFlag: false,
      unitId: null,
      showCommonModal: false,
      modalType: null,
      leaseHdrDetails: null,
      vendorDetails: [],
      emailList: []
    };
  },
  mounted() {
    this.getModulesList();
    this.setDefaultEntityType();
    this.setDefaultCategoryType();
    this.setDefaultSourceType();
    this.setDefaultPriorityType();
    this.editMode = true;
    this.getCustomerQueryRaisedBy();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addCustomerQuery();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getVendorDetailsByUnit(unitId) {
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getVendorDetailsByUnit', unitId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200 && resp.data.data[0].vendor_name) {
            this.vendorDetails = resp.data.data;
            const filterEmail = this.vendorDetails.filter(data => data.email);
            this.emailList = filterEmail.map(elem => elem.email);
            this.email = this.emailList
              .toString()
              .split(',')
              .join(', ');
          }
        });
    },
    getCustomerDetailsByUnit(unitId) {
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getCustomerDetailsByUnit', unitId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.email = resp.data.data.email;
          }
        });
    },
    getLeaseTenantList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_prj_unit_id: this.unit.value
      };
      this.loader = true;
      this.$store
        .dispatch('leases/getLeaseTenantList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.leaseHdrDetails = resp.data.data.page[0];
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
    },
    getModulesList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_name: 'FMS',
        receipt_flag: this.receipt_flag ? true : false
      };
      this.loader = true;
      this.$store
        .dispatch('resp/getModulesList', payload)
        .then(response => {
          this.loader = false;
          this.selectedModule.id = response.data.data.page[0].module_id;
          this.selectedModule.name = response.data.data.page[0].module_name;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setDefaultEntityType() {
      const entityTypeValue = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.CC_ENTITY_TYPE,
        'Unit'
      );
      entityTypeValue.then(key => {
        this.entityType.text = key[0].value_meaning;
        this.entityType.value = key[0].value_code;
      });
    },
    setDefaultCategoryType() {
      const categoryValue = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.CC_CAT,
        'QUY'
      );
      categoryValue.then(key => {
        this.category.text = key[0].value_meaning;
        this.category.value = key[0].value_code;
      });
    },
    setDefaultSourceType() {
      const sourceValue = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.CC_QUERY_SOURCE,
        'Walk-in'
      );
      sourceValue.then(key => {
        this.source.text = key[0].value_meaning;
        this.source.value = key[0].value_code;
      });
    },
    setDefaultPriorityType() {
      const priorityValue = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.CC_PRIORITY,
        'NORMAL'
      );
      priorityValue.then(key => {
        this.priority.text = key[0].value_meaning;
        this.priority.value = key[0].value_code;
      });
    },
    addCustomerQuery() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          le_id: this.legalEntity.value,
          query_id: this.queryId,
          module_id: this.selectedModule.id,
          project_id: this.project.value,
          entity_type: this.entityType.value,
          entity_id: this.getEntityIdByEntityType(),
          query_cat: this.category.value,
          query_sub_cat: this.subCategory.value,
          query_sub_sub_cat: this.subSubCategory.value,
          query_source: this.source.value,
          priority: this.priority.value,
          status: this.status.value,
          email: this.email,
          contact_no: this.contactNum,
          raised_by: this.raisedBy.text,
          appointment_date: this.appointmentDate,
          other_contact_no: this.addContactNumber,
          body: this.comments,
          appointment_time_from: this.fromTimeToTime[0],
          appointment_time_to: this.fromTimeToTime[1]
        };
        this.loader = true;
        this.$store
          .dispatch('realManageAdmin/addCustomerQuery', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 201) {
              this.editMode = false;
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.queryNum = response.data.data.document_num;
              this.tat = response.data.data.tat;
              this.tatUom.value = response.data.data.tat_uom_vset;
              this.tatUom.text = response.data.data.tat_uom_meaning;
              this.queryFlag = true;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getCustomerQueryRaisedBy() {
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getCustomerQueryRaisedBy')
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.raisedBy.text = resp.data.data.user_email;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEntityIdByEntityType() {
      switch (this.entityType.value) {
        case 'Tower':
          return this.tower.value;
        case 'Floor':
          return this.floor.value;
        case 'Unit':
          return this.unit.value;
        // case 'CommonArea':
        //   if (this.tower.value) {
        //     if (this.tower.value && this.floor.value) {
        //       return this.floor.value;
        //     }
        //     return this.tower.value;
        //   }
        //   break;
      }
    },
    getCustomerQueryValueSetList(valueParams) {
      if (this.selectedModule.id) {
        const payload = {
          moduleId: this.selectedModule.id,
          catType: { valueset_type: valueParams }
        };
        this.loader = true;
        this.$store
          .dispatch('realManageAdmin/getCustomerQueryValueSet', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.openValueSetModal(response.data.data);
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert('Please Select the Module First');
      }
    },
    openModuleBasedComponent(component) {
      if (this.selectedModule.name === 'FMS') {
        switch (component) {
          case 'project':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_PROJECT);
            break;
          case 'tower':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_TOWER);
            break;
          case 'floor':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_FLOOR);
            break;
          case 'unit':
            this.openValueSetModal(appStrings.VALUESETTYPE.NFA_FMS_UNIT);
            break;
        }
      } else if (this.selectedModule.name === 'Lease Management') {
        switch (component) {
          case 'project':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
            break;
          case 'tower':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_TOWER_LIST);
            break;
          case 'floor':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_FLOOR_LIST);
            break;
          case 'unit':
            this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_UNIT_LIST);
            break;
        }
      } else {
        alert('Please Select the Module First');
      }
    },
    opendDependentUom(vsetCode) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.openValueSetModal('UOM', findTimeObj.value_set_dtl_id);
            }
          }
        });
    },
    setParentVsetId(vsetCode, param) {
      switch (vsetCode) {
        case appStrings.VALUESETTYPE.UOM:
          this.parent_value_set_id = param;
          break;
        case appStrings.VALUESETTYPE.CC_SUB_SUB_CAT:
          this.parent_value_set_id = this.subCategoryId;
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.parent_value_set_id = this.project.value;
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          this.parent_value_set_id = this.tower.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.parent_value_set_id = this.tower.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.parent_value_set_id = this.floor.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.LEASE_UNIT_LIST:
          this.parent_value_set_id = this.floor.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT:
          this.parent_value_set_id = this.unit.value;
          break;
        case appStrings.VALUESETTYPE.LEASE_PROJECT_LIST:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    openValueSetModal(vsetCode, valuseSetDtlId) {
      this.vsetCode = vsetCode;
      this.setParentVsetId(vsetCode, valuseSetDtlId);
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.CC_CAT:
          this.category = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_SUB_CAT:
          this.subCategory = {
            text: item.value_meaning,
            value: item.value_code
          };
          this.subCategoryId = item.value_set_dtl_id;
          this.subSubCategory = this.defaultValue;
          break;
        case appStrings.VALUESETTYPE.CC_SUB_SUB_CAT:
          this.subSubCategory = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_QUERY_SOURCE:
          this.source = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_PRIORITY:
          this.priority = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_STATUS:
          this.status = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_ENTITY_TYPE:
          this.entityType = {
            text: item.value_meaning,
            value: item.value_code
          };
          this.tower = this.defaultValue;
          this.floor = this.defaultValue;
          this.unit = this.defaultValue;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_PROJECT:
          this.project = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.legalEntity = {
            text: item.le_name,
            value: item.le_id
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.tower = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.tower = {
            text: item.tower_name,
            value: item.tower_id
          };
          this.floor = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.tower = {
            text: item.tower_name,
            value: item.tower_id
          };
          this.floor = {
            text: item.floor_name,
            value: item.floor_id
          };
          this.unit = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.unitId = this.unit.value;
          this.custFlag = true;
          this.getVendorDetailsByUnit(this.unitId);
          break;
        case appStrings.VALUESETTYPE.LEASE_PROJECT_LIST:
          this.project = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.legalEntity = {
            text: item.le_name,
            value: item.le_id
          };
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
          this.tower = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          this.tower = {
            text: item.tower_name,
            value: item.lease_tower_id
          };
          this.floor = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.LEASE_UNIT_LIST:
          this.tower = {
            text: item.lease_tower_name,
            value: item.lease_tower_id
          };
          this.floor = {
            text: item.lease_floor_name,
            value: item.lease_floor_id
          };
          this.unit = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.custLeaseFlag = true;
          this.getCustomerDetailsByUnit(this.unit.value);
          this.getLeaseTenantList();
          break;
        case appStrings.VALUESETTYPE.UOM:
          this.tatUom = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.project = this.defaultValue;
      this.tower = this.defaultValue;
      this.floor = this.defaultValue;
      this.unit = this.defaultValue;
      this.email = null;
      this.custFlag = false;
      this.custLeaseFlag = false;
      this.showModal = false;
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    showHideCustomerModal(flag) {
      this.showCustomerModal = flag;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.selectedModule.id:
          this.selectedModule = {
            id: null,
            name: null
          };
          break;
        case this.project.value:
          this.project = this.defaultValue;
          break;
        case this.entityType.value:
          this.entityType = this.defaultValue;
          break;
        case this.tower.value:
          this.tower = this.defaultValue;
          break;
        case this.floor.value:
          this.floor = this.defaultValue;
          break;
        case this.unit.value:
          this.unit = this.defaultValue;
          this.custFlag = false;
          break;
        case this.category.value:
          this.category = this.defaultValue;
          break;
        case this.subCategory.value:
          this.subCategory = this.defaultValue;
          this.subSubCategory = this.defaultValue;
          break;
        case this.subSubCategory.value:
          this.subSubCategory = this.defaultValue;
          break;
        case this.source.value:
          this.source = this.defaultValue;
          break;
        case this.priority.value:
          this.priority = this.defaultValue;
          break;
        case this.status.value:
          this.status = this.defaultValue;
          break;
      }
    }
    // getSelectedPartyCustomer(item) {
    //   this.party.text = item.party_name;
    //   this.party.value = item.party_id;
    //   this.partyType = item.party_type;
    //   this.showPartyModal = false;
    // },
    // showHidePartyModal(flag) {
    //   this.showPartyModal = flag;
    //   this.setCustCode = setTimeout(() => {
    //     this.eventBus.$emit('userType', 'CUST');
    //   }, 0);
    // },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
