import { format } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
import AssignQuery from './assignQuery';
import AssignTask from './assignTask';
import TaskCommunication from './taskCommunication';
import SendEmail from './sendEmail';
import SendSms from './sendSms';
import ActionTaken from './actionTaken';
import AssignCurrentTask from './assignCurrentTask';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'editCustomerQueries',
  components: {
    DatePicker,
    party,
    ModelSelect,
    AssignQuery,
    AssignTask,
    TaskCommunication,
    SendEmail,
    SendSms,
    ActionTaken,
    AssignCurrentTask
  },
  props: ['customerQueryDetails'],
  data() {
    return {
      queryDetails: {
        document_num: null,
        le_name: null,
        module_name: null,
        entity_type: null,
        entity_name: null,
        query_cat_meaning: null,
        query_sub_cat_meaning: null,
        query_sub_sub_cat_meaning: null,
        query_source_meaning: null,
        priority: null,
        status_meaning: null,
        email: null,
        contact_no: null,
        raised_by: null,
        appointment_date: null,
        appointment_time_from: null,
        appointment_time_to: null,
        tat: null,
        tat_uom_meaning: null,
        other_contact_no: null,
        subject: null,
        comments: null
      },
      queryAssign: null,
      responseMsg: null,
      isSuccess: null,
      editMode: false,
      showAlert: false,
      loader: false,
      showAssignModal: false,
      assignDetails: null,
      batchName: null,
      scheduleType: {
        value: null,
        text: null
      },
      batchId: 0,
      paymentPriority: null,
      showPartyModal: false,
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentDate: null,
      paymentMode: {
        value: null,
        text: null
      },
      unsubscribe: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      lastUpdatedLogin: null,
      lastUpdatedBy: null,
      dueFromToDate: null,
      payGroup: {
        value: null,
        text: null
      },
      party: {
        value: null,
        text: null
      },
      partyType: null,
      status: 'NEW',
      remarks: null,
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      createdBy: null,
      taskCreationGrid: [
        {
          query_task_id: 0,
          task_num: 1,
          task_status_vset: 'NEW',
          task_status_vset_meaning: 'NEW',
          cc_priority_vset: 'NORMAL',
          cc_priority_vset_meaning: 'NORMAL',
          task_date: commonHelper.formattedDate(new Date()),
          remarks: null
        }
      ],
      taskCreationField: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'task_num',
          label: 'Task Number'
        },
        {
          key: 'task_status_vset_meaning',
          label: 'Task Status'
        },
        {
          key: 'cc_priority_vset_meaning',
          label: 'Priority'
        },
        {
          key: 'task_date'
        },
        {
          key: 'task_time'
        },
        {
          key: 'remarks'
        },
        {
          key: 'assign',
          stickyColumn: true
        },
        {
          key: 'communication',
          class: 'text-center'
        }
      ],
      queryCommList: [],
      queryCommField: [
        {
          key: 'action'
        },
        {
          key: 'task'
        },
        {
          key: 'subject'
        },
        {
          key: 'from'
        },
        {
          key: 'to'
        },
        {
          key: 'cc'
        },
        {
          key: 'received'
        }
      ],
      isViewMore: false
    };
  },
  mounted() {
    this.queryDetails = this.customerQueryDetails;
    if (this.queryDetails.tat) {
      this.queryDetails.tat = parseInt(this.customerQueryDetails.tat);
    }
    if (this.customerQueryDetails) {
      this.getTaskCreationDetails(this.customerQueryDetails.query_id);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode && !this.showAssignModal) {
          this.addEditTaskCreation();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditTaskCreation() {
      const taskDetails = this.taskCreationGrid.map(elem => {
        return {
          query_task_id: elem.query_task_id ? elem.query_task_id : 0,
          task_status_vset: elem.task_status_vset,
          cc_priority_vset: elem.cc_priority_vset,
          task_date: elem.task_date,
          task_time: elem.task_time,
          remarks: elem.remarks
        };
      });
      const payload = {
        query_id: this.customerQueryDetails.query_id,
        query_task_details: taskDetails ? taskDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/addEditTaskCreation', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.taskCreationGrid = response.data.data;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getTaskCreationDetails(ccQueryId) {
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getTaskCreationDetails', ccQueryId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if (resp.data.data.length > 0) {
              this.taskCreationGrid = resp.data.data;
              this.taskCreationGrid.map(data => {
                data.assign = 'Details';
                data.communication = 'Details';
                return data;
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.CC_PRIORITY) {
        this.taskCreationGrid[this.selectedIndex].cc_priority_vset =
          item.value_code;
        this.taskCreationGrid[this.selectedIndex].cc_priority_vset_meaning =
          item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    // getSelectedPartyCustomer(item) {
    //   this.party.text = item.party_name;
    //   this.party.value = item.party_id;
    //   this.partyType = item.party_type;
    //   this.showPartyModal = false;
    // },
    // showHidePartyModal(flag) {
    //   this.showPartyModal = flag;
    //   this.setCustCode = setTimeout(() => {
    //     this.eventBus.$emit('userType', 'CUST');
    //   }, 0);
    // },

    openAssignModal(flag, item) {
      this.assignDetails = item;
      this.showAssignModal = flag;
    },
    assignQueryToCus(modalName) {
      this.queryAssign = modalName;
    },
    addNewRow(index) {
      if (this.editMode) {
        const tempVar = parseInt(this.taskCreationGrid[index].task_num);
        this.taskCreationGrid.push({
          query_task_id: 0,
          task_num: tempVar + 1,
          task_status_vset: 'NEW',
          task_status_vset_meaning: 'NEW',
          cc_priority_vset: 'NORMAL',
          cc_priority_vset_meaning: 'NORMAL',
          task_date: commonHelper.formattedDate(new Date()),
          remarks: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.taskCreationGrid.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'CC_PRIORITY') {
        this.taskCreationGrid[index].cc_priority_vset = null;
        this.taskCreationGrid[index].cc_priority_vset_meaning = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
