import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'assignCurrentTask',
  components: {
    DatePicker,
    ModelSelect
  },
  props: ['assignDetails'],
  data() {
    return {
      queryAssign: null,
      responseMsg: null,
      isSuccess: null,
      editMode: false,
      showAlert: false,
      loader: false,
      unsubscribe: null,
      selectedIndex: null,
      parent_value_set_id: null,
      showValueSetModal: false,
      assignCurrentTaskGrid: [
        {
          query_task_assignee_id: 0,
          assign_type: null,
          assign_type_vset_meaning: null,
          assignee_id: null,
          assignee: null,
          start_date: null,
          end_date: null
        }
      ],
      assignCurrentTaskField: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'assign_type_vset_meaning',
          label: 'Assignee Type'
        },
        {
          key: 'assignee'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    if (this.assignDetails) {
      this.getCustomerQueryTaskAssignedList(this.assignDetails.query_task_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditAssignCurrentTask();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    checkAssignType(assignType, index) {
      switch (assignType) {
        case 'Department':
          this.openValueSetModal('DEPARTMENT', index);
          break;
        case 'Employee':
          this.openValueSetModal('CC_EMPLOYEE', index);
          break;
        case 'Group':
          this.openValueSetModal('CC_GROUP', index);
          break;
        default:
          alert('Please Select Assign Type');
          break;
      }
    },
    addEditAssignCurrentTask() {
      const assignTaskDetails = this.assignCurrentTaskGrid.map(elem => {
        return {
          query_task_assignee_id: elem.query_task_assignee_id
            ? elem.query_task_assignee_id
            : 0,
          assignee_type: elem.assign_type,
          assignee_id: elem.assignee_id,
          start_date: elem.start_date,
          end_date: elem.end_date
        };
      });
      const payload = {
        query_task_id: this.assignDetails.query_task_id,
        query_task_assignee_details: assignTaskDetails
          ? assignTaskDetails
          : null
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/addEditAssignCurrentTask', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getCustomerQueryTaskAssignedList(queryTaskId) {
      const payload = {
        queryTaskId: queryTaskId
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getCustomerQueryTaskAssignedList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if (resp.data.data.length > 0) {
              this.assignCurrentTaskGrid = resp.data.data;
            } else {
              this.editMode = true;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.selectedIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSIGNEE_TYPE) {
        this.assignCurrentTaskGrid[this.selectedIndex].assign_type =
          item.value_code;
        this.assignCurrentTaskGrid[
          this.selectedIndex
        ].assign_type_vset_meaning = item.value_meaning;
        this.assignCurrentTaskGrid[this.selectedIndex].assignee = null;
        this.assignCurrentTaskGrid[this.selectedIndex].assignee_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_EMPLOYEE) {
        this.assignCurrentTaskGrid[this.selectedIndex].assignee =
          item.value_code;
        this.assignCurrentTaskGrid[this.selectedIndex].assignee_id =
          item.value_set_dtl_id;
        this.assignCurrentTaskGrid[
          this.selectedIndex
        ].start_date = commonHelper.formattedDate(new Date());
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CC_GROUP) {
        this.assignCurrentTaskGrid[this.selectedIndex].assignee =
          item.value_code;
        this.assignCurrentTaskGrid[this.selectedIndex].assignee_id =
          item.value_set_dtl_id;
        this.assignCurrentTaskGrid[
          this.selectedIndex
        ].start_date = commonHelper.formattedDate(new Date());
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DEPARTMENT) {
        this.assignCurrentTaskGrid[this.selectedIndex].assignee =
          item.value_meaning;
        this.assignCurrentTaskGrid[this.selectedIndex].assignee_id =
          item.value_code;
        this.assignCurrentTaskGrid[
          this.selectedIndex
        ].start_date = commonHelper.formattedDate(new Date());
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    assignQueryToCus(modalName) {
      this.queryAssign = modalName;
    },
    addNewRow() {
      if (this.editMode) {
        this.assignCurrentTaskGrid.push({
          query_task_assignee_id: 0,
          assign_type: null,
          assign_type_vset_meaning: null,
          assignee_id: null,
          assignee: null,
          start_date: null,
          end_date: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.assignCurrentTaskGrid.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'ASSIGNEE_TYPE') {
        this.assignCurrentTaskGrid[index].assign_type = null;
        this.assignCurrentTaskGrid[index].assign_type_vset_meaning = null;
        this.assignCurrentTaskGrid[index].assignee = null;
        this.assignCurrentTaskGrid[index].assignee_id = null;
        this.assignCurrentTaskGrid[index].start_date = null;
      } else if (vsetCode === 'ASSIGNEE') {
        this.assignCurrentTaskGrid[index].assignee = null;
        this.assignCurrentTaskGrid[index].assignee_id = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
