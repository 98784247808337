import addCustomerQueries from './addCustomerQueries';
import ModulesList from '../../admin/responsibility/searchModule';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import EditCustomerQueries from './editCustomerQueries';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../store';
import SendSms from '../customerQueries/editCustomerQueries/sendSms';
import SendEmail from '../customerQueries/editCustomerQueries/sendEmail';
import ActionTaken from '../customerQueries/editCustomerQueries/actionTaken';
import sentMail from './sentMail';
export default {
  name: 'customerQueries',
  watch: {
    currentPage: function() {
      this.getCustomerQueries();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCustomerQueries();
    }
  },
  components: {
    addCustomerQueries,
    DatePicker,
    ModulesList,
    EditCustomerQueries,
    SendSms,
    SendEmail,
    ActionTaken,
    sentMail
  },
  data() {
    return {
      viewMode: false,
      loader: false,
      showModal: false,
      unsubscribe: null,
      isViewMore: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      parent_value_set_id: null,
      currentPage: 1,
      totalRows: null,
      tab: null,
      showBatchPaymentModal: false,
      batchPaymentDetails: null,
      customerQueryDetails: null,
      showValueSetModal: false,
      depVsetParam: null,
      defaultValue: {
        value: null,
        text: null
      },
      queryNumber: '',
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedModule: {
        name: null,
        id: null
      },
      category: {
        value: null,
        text: null
      },
      subCategory: {
        value: null,
        text: null
      },
      subSubCategory: {
        value: null,
        text: null
      },
      source: {
        value: null,
        text: null
      },
      priority: {
        value: null,
        text: null
      },
      status: {
        value: null,
        text: null
      },
      customer: {
        value: '',
        text: ''
      },
      entity: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      tower: {
        value: null,
        text: null
      },
      floor: {
        value: null,
        text: null
      },
      unit: {
        value: null,
        text: null
      },
      tatUom: {
        value: null,
        text: null
      },
      tat: null,
      subCategoryId: null,
      email: '',
      fromDate: '',
      toDate: '',
      subject: '',
      raisedBy: '',
      userId: store.state.auth.userId,
      userName: null,
      entityTypeValueset: [],
      customerQueryData: [],
      customerQueryField: [
        {
          key: 'whatsapp',
          label: 'WhatsApp',
          class: 'text-center',
          stickyColumn: true
        },
        {
          key: 'mail',
          stickyColumn: true
        },
        {
          key: 'action',
          stickyColumn: true
        },
        {
          key: 'comments',
          stickyColumn: true
        },
        // {
        //   key: 'inbox',
        //   stickyColumn: true,
        //   class: 'text-center'
        // },
        {
          key: 'document_num',
          label: 'Query Number',
          class: 'text-center'
        },
        {
          key: 'module_name',
          label: 'Module',
          class: 'text-center'
        },
        {
          key: 'prj_name',
          label: 'Project'
        },
        {
          key: 'entity_type',
          label: 'Entity Type',
          class: 'text-center'
        },
        {
          key: 'entity_name'
        },
        {
          key: 'query_cat_meaning',
          label: 'Category',
          class: 'text-center'
        },
        {
          key: 'query_sub_cat_meaning',
          label: 'Sub Category',
          class: 'text-center'
        },
        {
          key: 'query_sub_sub_cat_meaning',
          label: 'Sub Sub Category'
        },
        {
          key: 'query_source_meaning',
          label: 'Source'
        },
        {
          key: 'priority_meaning',
          label: 'Priority'
        },
        {
          key: 'status_meaning',
          label: 'Status'
        },
        {
          key: 'email'
        },
        {
          key: 'contact_no'
        }
        // {
        //   key: 'appointment_date'
        // },
        // {
        //   key: 'appointment_time_from',
        //   label: 'Time From'
        // },
        // {
        //   key: 'appointment_time_to',
        //   label: 'Time To'
        // },
        // {
        //   key: 'tat',
        //   label: 'TAT'
        // },
        // {
        //   key: 'tat_uom_vset',
        //   label: 'TAT UOM'
        // },
        // {
        //   key: 'other_contact_no',
        //   label: 'Additional Contact No'
        // },
      ],
      queryAssign: null,
      rowDetails: null,
      showCommonModal: false,
      showSentMailModal: false
    };
  },
  mounted() {
    this.userName = this.getUserName();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.viewMode = false;
          this.hideBatchPaymentModal(true);
          this.batchPaymentDetails = null;
          this.customerQueryDetails = null;
        }
      }
    });
  },
  methods: {
    showHideSentMailModal(flag, item) {
      this.showSentMailModal = flag;
      if (flag) {
        this.rowDetails = item;
      }
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    showHideCommonModal(flag, modalName, item) {
      this.showCommonModal = flag;
      this.queryAssign = modalName;
      if (flag && item) {
        this.rowDetails = item;
      }
      if (!flag) {
        this.getCustomerQueries();
      }
    },
    assignQueryToCus(modalName) {
      this.queryAssign = modalName;
    },
    getUserName() {
      const tempName = store.state.auth.userName;
      const name = tempName.slice(tempName.lastIndexOf('-') + 1);
      return name;
    },
    getCustomerQueryValueSetList(valueParams) {
      const payload = {
        moduleId: this.selectedModule.id,
        catType: { valueset_type: valueParams }
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getCustomerQueryValueSet', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openValueSetModal(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerQueries() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectedLegalEntity.value, //not null
        user_id: store.state.auth.userId, //not null
        module_id: this.selectedModule.id, //not null
        entity_type: this.entity.value, //not null
        entity_id: this.getEntityId(), //not null
        document_num: this.queryNumber,
        query_cat: this.category.value,
        query_sub_cat: this.subCategory.value,
        query_sub_sub_cat: this.subSubCategory.value,
        query_source: this.source.value,
        priority: this.priority.value,
        status: this.status.value,
        customer_id: this.customer.value,
        email: this.email,
        raised_by: this.raisedBy,
        tat: this.tat,
        tat_uom_vset: this.tatUom.value,
        subject: this.subject,
        from_date: commonHelper.formattedDate(this.fromDate),
        to_date: commonHelper.formattedDate(this.toDate)
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getCustomerQueryList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerQueryData = response.data.data.page;
            this.customerQueryData.map(data => {
              data.mail = 'Mail';
              data.sms = 'SMS';
              data.action = 'Action';
              data.other = 'Other';
              data.inbox = 'Inbox';
            });
            this.totalRows = response.data.data.total_elements;
          } else {
            alert(`Request returned status code: ${response.response.status}`);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.rowDetails = item;
      this.viewMode = true;
      this.showBatchPaymentModal = true;
      this.customerQueryDetails = item;
    },
    openValueSetModal(vsetCode, valuseSetDtlId) {
      this.vsetCode = vsetCode;
      this.setParentVsetId(vsetCode, valuseSetDtlId);
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.CC_CAT:
          this.category = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_SUB_CAT:
          this.subCategory = {
            text: item.value_meaning,
            value: item.value_code
          };
          this.subCategoryId = item.value_set_dtl_id;
          this.subSubCategory = this.defaultValue;
          break;
        case appStrings.VALUESETTYPE.CC_SUB_SUB_CAT:
          this.subSubCategory = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_QUERY_SOURCE:
          this.source = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_PRIORITY:
          this.priority = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_STATUS:
          this.status = {
            text: item.value_meaning,
            value: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.CC_ENTITY_TYPE:
          this.entity = {
            text: item.value_meaning,
            value: item.value_code
          };
          this.tower = this.defaultValue;
          this.floor = this.defaultValue;
          this.unit = this.defaultValue;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_PROJECT:
          this.project = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.selectedLegalEntity = {
            text: item.le_name,
            value: item.le_id
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.tower = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.tower = {
            text: item.tower_name,
            value: item.tower_id
          };
          this.floor = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.tower = {
            text: item.tower_name,
            value: item.tower_id
          };
          this.floor = {
            text: item.floor_name,
            value: item.floor_id
          };
          this.unit = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.LEASE_PROJECT_LIST:
          this.project = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          this.selectedLegalEntity = {
            text: item.le_name,
            value: item.le_id
          };
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
          this.tower = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          this.tower = {
            text: item.tower_name,
            value: item.lease_tower_id
          };
          this.floor = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.LEASE_UNIT_LIST:
          this.tower = {
            text: item.lease_tower_name,
            value: item.lease_tower_id
          };
          this.floor = {
            text: item.lease_floor_name,
            value: item.lease_floor_id
          };
          this.unit = {
            text: item.value_code,
            value: item.value_set_dtl_id
          };
          break;
        case appStrings.VALUESETTYPE.UOM:
          this.tatUom = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
      }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showModal = false;
      this.getValuesetNameForEntity(this.selectedModule.id);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearParams() {
      this.queryNumber = '';
      this.selectedLegalEntity.text = null;
      this.selectedLegalEntity.value = null;
      this.selectedModule.name = null;
      this.selectedModule.id = null;
      this.category.text = null;
      this.category.value = null;
      this.subCategory.text = null;
      this.subCategory.value = null;
      this.subSubCategory.text = null;
      this.subSubCategory.value = null;
      this.source.text = null;
      this.source.value = null;
      this.priority.text = null;
      this.priority.value = null;
      this.status.text = null;
      this.status.value = null;
      this.customer.text = '';
      this.customer.value = '';
      this.email = '';
      this.fromDate = '';
      this.toDate = '';
      this.entity.text = null;
      this.entity.value = null;
      this.project.text = null;
      this.project.value = null;
      this.tower.text = null;
      this.tower.value = null;
      this.floor.text = null;
      this.floor.value = null;
      this.unit.text = null;
      this.unit.value = null;
      this.tatUom.text = null;
      this.tatUom.value = null;
      this.tat = null;
      this.subject = '';
      this.customerQueryData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.isViewMore = false;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
    },
    hideBatchPaymentModal(flag) {
      this.showBatchPaymentModal = flag;
    },
    chooseValueset(filter) {
      switch (filter) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'cat':
          this.getCustomerQueryValueSetList(appStrings.VSETTYPEFORVSETNAME.CAT);
          break;
        case 'sub_cat':
          this.getCustomerQueryValueSetList(
            appStrings.VSETTYPEFORVSETNAME.SUB_CAT
          );
          break;
        case 'sub_sub_cat':
          this.getCustomerQueryValueSetList(
            appStrings.VSETTYPEFORVSETNAME.SUB_SUB_CAT
          );
          break;
        case 'query_source':
          this.getCustomerQueryValueSetList(
            appStrings.VSETTYPEFORVSETNAME.SOURCE
          );
          break;
        case 'priority':
          this.getCustomerQueryValueSetList(
            appStrings.VSETTYPEFORVSETNAME.PRIORITY
          );
          break;
        case 'status':
          this.getCustomerQueryValueSetList(
            appStrings.VSETTYPEFORVSETNAME.STATUS
          );
          break;
        case 'entity_type':
          this.getCustomerQueryValueSetList(
            appStrings.VSETTYPEFORVSETNAME.ENTITY_TYPE
          );
          break;
        case 'customer':
          this.openValueSetModal(appStrings.VALUESETTYPE.CUSTOMER_NAME);
          break;
        case 'tat_uom':
          this.opendDependentUom(appStrings.VALUESETTYPE.UOM_CLASS);
          break;
      }
    },
    opendDependentUom(vsetCode) {
      const payload = {
        valueSetName: vsetCode,
        dependentVariable: {
          user_id: this.$store.state.auth.userId,
          res_id: this.$store.state.shared.responsibilityId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const findTimeObj = response.data.data.page.find(
              vsetData => vsetData.value_code === 'TIME'
            );
            if (findTimeObj) {
              this.openValueSetModal('UOM', findTimeObj.value_set_dtl_id);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getValuesetNameForEntity(moduleId) {
      this.loader = true;
      this.$store
        .dispatch(
          'realManageAdmin/getModuleBasedValuesetNameForEntity',
          moduleId
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.entityTypeValueset = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openEntity(type) {
      const TYPE = new RegExp(type, 'i');
      const entityInfo = this.entityTypeValueset.filter(elem =>
        TYPE.test(elem.entity_type)
      );
      this.openValueSetModal(entityInfo[0].valueset);
    },
    setParentVsetId(vsetCode, param) {
      switch (vsetCode) {
        case appStrings.VALUESETTYPE.UOM:
          this.parent_value_set_id = param;
          break;
        case appStrings.VALUESETTYPE.CC_SUB_SUB_CAT:
          this.parent_value_set_id = this.subCategoryId;
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
        case appStrings.VALUESETTYPE.NFA_FMS_TOWER:
          this.parent_value_set_id = this.project.value;
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          this.parent_value_set_id = this.tower.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_FLOOR:
          this.parent_value_set_id = this.tower.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_UNIT:
          this.parent_value_set_id = this.floor.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.LEASE_UNIT_LIST:
          this.parent_value_set_id = this.floor.value;
          this.depVsetParam = this.project.value;
          break;
        case appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT:
          this.parent_value_set_id = this.unit.value;
          break;
        case appStrings.VALUESETTYPE.LEASE_PROJECT_LIST:
          this.parent_value_set_id = this.selectedLegalEntity.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    getEntityId() {
      switch (this.entity.value) {
        case 'CommonArea':
          if (this.tower.value) {
            if (this.tower.value && this.floor.value) {
              return this.floor.value;
            }
            return this.tower.value;
          }
          break;
        case 'Unit':
          return this.unit.value;
      }
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.selectedModule.id:
          this.selectedModule = {
            id: null,
            name: null
          };
          break;
        case this.project.value:
          this.project = this.defaultValue;
          break;
        case this.entity.value:
          this.entity = this.defaultValue;
          break;
        case this.tower.value:
          this.tower = this.defaultValue;
          break;
        case this.floor.value:
          this.floor = this.defaultValue;
          break;
        case this.unit.value:
          this.unit = this.defaultValue;
          break;
        case this.category.value:
          this.category = this.defaultValue;
          break;
        case this.subCategory.value:
          this.subCategory = this.defaultValue;
          this.subSubCategory = this.defaultValue;
          break;
        case this.subSubCategory.value:
          this.subSubCategory = this.defaultValue;
          break;
        case this.source.value:
          this.source = this.defaultValue;
          break;
        case this.priority.value:
          this.priority = this.defaultValue;
          break;
        case this.status.value:
          this.status = this.defaultValue;
          break;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
