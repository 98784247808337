export default {
  name: 'customerDetails',
  components: {},
  props: ['unitId', 'vendorDetails'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      customerData: [],
      customerField: [
        {
          key: 'vendor_name',
          label: 'Party Name'
        },
        {
          key: 'pan_no',
          label: 'PAN No.'
        },
        {
          key: 'email',
          stickyColumn: true
        }
      ]
    };
  },
  mounted() {
    if (this.vendorDetails) {
      this.customerData = this.vendorDetails;
    }
  },
  methods: {}
};
