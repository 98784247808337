import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueCkeditor from 'vue-ckeditor5';
import { email, required } from 'vuelidate/lib/validators';
import appStrings from '@/app/utility/string.utility';
import { format } from 'date-fns';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import uploadQueryDocument from './uploadQueryDocument';
export default {
  name: 'sendEmail',
  components: {
    'vue-ckeditor': VueCkeditor.component,
    openKmAuthentication,
    UploadDocument,
    uploadQueryDocument
  },
  validations: {
    toEmail: { email, required },
    ccEmail: { email },
    bccEmail: { email }
  },
  props: ['rowDetails'],
  data() {
    return {
      editors: {
        classic: ClassicEditor
      },
      responseMsg: null,
      isSuccess: null,
      showAlert: false,
      loader: false,
      toEmail: null,
      ccEmail: null,
      bccEmail: null,
      subject: null,
      comments: null,
      commId: 0,
      showOpenKmModal: false,
      historyType: null,
      showHistory: false,
      isDraft: false,
      seperateEmail: [],
      commTypeVset: 'SENDGRID',
      selectedGridData: null,
      selectedQueryDetails: null,
      openkmAuthToken: null,
      validEmail: null,
      showUploadQueryModal: false,
      legalEntity: {
        text: null,
        value: null
      },
      emailDetails: null,
      saveDraft: {
        toEmail: null,
        ccEmail: null,
        bccEmail: null,
        subject: null,
        body: null,
        commId: null,
        query_id: null
      }
    };
  },
  mounted() {
    if (this.rowDetails.query_id) {
      this.getTempEmailById();
    }
    if (this.rowDetails) {
      setTimeout(() => {
        this.comments = this.rowDetails.comments;
      }, 0);
      this.toEmail = this.rowDetails.email;
      this.subject = this.rowDetails.document_num;
    }
  },
  methods: {
    showHideUploadQueryModal(flag) {
      this.showUploadQueryModal = flag;
      if (flag) {
        this.emailDetails = this.rowDetails;
        this.saveDraft.toEmail = this.toEmail;
        this.saveDraft.ccEmail = this.ccEmail;
        this.saveDraft.bccEmail = this.bccEmail;
        this.saveDraft.subject = this.subject;
        this.saveDraft.body = this.comments;
        this.saveDraft.commId = this.commId;
        this.saveDraft.query_id = this.rowDetails.query_id;
      } else {
        this.getTempEmailById();
      }
    },
    checkFunction() {
      const val = this.toEmail.split(',');
      this.seperateEmail = val;
    },
    sendMail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          menu_id: this.$store.state.shared.menuId,
          comm_id: this.isDraft ? this.commId : 0,
          comm_type_vset: this.commTypeVset,
          query_id: this.rowDetails.query_id,
          to_email: this.toEmail,
          cc_email: this.ccEmail,
          bcc_email: this.bccEmail,
          subject: this.subject,
          body: this.mailBodyFunction(
            format(new Date(), appStrings.DATETIMEFNSFORMAT),
            this.toEmail,
            this.ccEmail ? this.ccEmail : '',
            this.bccEmail ? this.bccEmail : '',
            this.comments
          ),
          is_draft: false
        };
        this.loader = true;
        this.$store
          .dispatch('realManageAdmin/saveEmailInTempTable', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isDraft = response.data.data.is_draft;
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    getTempEmailById() {
      this.loader = true;
      this.$store
        .dispatch(
          'realManageAdmin/getTempEmailByCommId',
          this.rowDetails.query_id
        )
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if (resp.data.data) {
              this.toEmail = resp.data.data.to_email;
              this.ccEmail = resp.data.data.cc_email;
              this.bccEmail = resp.data.data.bcc_email;
              this.subject = resp.data.data.subject;
              this.comments = resp.data.data.body;
              this.commId = resp.data.data.comm_id;
              this.isDraft = resp.data.data.is_draft;
            } else {
              this.toEmail = this.rowDetails.email;
              this.subject = this.rowDetails.document_num;
              this.comments =
                this.rowDetails.comments + appStrings.MAILBODY.FOOTER;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    mailBodyFunction(date, toEmail, ccEmail, bccEmail, comments) {
      return (
        appStrings.MAILBODY.CLOSING +
        date +
        appStrings.MAILBODY.TO +
        toEmail +
        appStrings.MAILBODY.CC +
        ccEmail +
        appStrings.MAILBODY.BCC +
        bccEmail +
        comments
      );
    },
    saveEmailInTempTable() {
      const payload = {
        menu_id: this.$store.state.shared.menuId,
        comm_id: this.isDraft ? this.commId : 0,
        comm_type_vset: this.commTypeVset,
        query_id: this.rowDetails.query_id,
        to_email: this.toEmail,
        cc_email: this.ccEmail,
        bcc_email: this.bccEmail,
        subject: this.subject,
        body: this.comments,
        is_draft: true
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/saveEmailInTempTable', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.isDraft = response.data.data.is_draft;
            this.commId = response.data.data.comm_id;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    discardEmail() {
      const payload = {
        queryId: this.rowDetails.query_id,
        commId: this.commId
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/discardEmailById', payload)
        .then(resp => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    closeAssignQuery() {
      this.$emit('closeAssignQuery');
    }
    // checkOpenKmPasswordSaved() {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/checkSavedPassword')
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         if (response.data.data) {
    //           this.getOpenKmAuthToken();
    //         } else {
    //           this.showHideOpenKmModal(true);
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // getOpenKmAuthToken() {
    //   const payload = {
    //     password: null,
    //     saved_password_flag: true,
    //     user_name: null
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/openKmAuthenticate', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         this.openkmAuthToken = response.data.data.token;
    //         this.selectedGridData = {
    //           ...this.selectedQueryDetails,
    //           nfa_cat_vset: null,
    //           nfa_cat: null,
    //           nfa_sub_cat_vset: null,
    //           nfa_sub_cat: null
    //         };
    //         this.showHideHistory(true, 'uploadDocument');
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // showHideOpenKmModal(flag) {
    //   this.showOpenKmModal = flag;
    // },
    // setOpenKmToken(token) {
    //   this.showHideOpenKmModal(false);
    //   this.openkmAuthToken = token;
    //   this.selectedGridData = {
    //     ...this.selectedQueryDetails,
    //     nfa_cat_vset: null,
    //     nfa_cat: null,
    //     nfa_sub_cat_vset: null,
    //     nfa_sub_cat: null
    //   };
    //   this.showHideHistory(true, 'uploadDocument');
    // },
    // showHideHistory(flag, historyType) {
    //   this.showHistory = flag;
    //   this.historyType = historyType;
    // }
  }
};
