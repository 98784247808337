import { format } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'taskCommunication',
  components: {
    DatePicker,
    party,
    ModelSelect
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      responseMsg: null,
      isSuccess: null,
      showAlert: false,
      loader: false,
      batchName: null,
      scheduleType: {
        value: null,
        text: null
      },
      batchId: 0,
      paymentPriority: null,
      showPartyModal: false,
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentDate: null,
      paymentMode: {
        value: null,
        text: null
      },
      unsubscribe: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      lastUpdatedLogin: null,
      lastUpdatedBy: null,
      dueFromToDate: null,
      payGroup: {
        value: null,
        text: null
      },
      party: {
        value: null,
        text: null
      },
      partyType: null,
      status: 'NEW',
      remarks: null,
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      createdBy: null,
      smsList: [],
      smsFields: [
        {
          key: 'contact_name'
        },
        {
          key: 'contact_number'
        },
        {
          key: 'receipients'
        },
        {
          key: 'forwarded_by'
        }
      ],
      emailList: [],
      emailFields: [
        {
          key: 'to'
        },
        {
          key: 'cc'
        },
        {
          key: 'subject'
        },
        {
          key: 'receipients'
        },
        {
          key: 'forwarded_by'
        }
      ],
      actionList: [],
      actionFields: [
        {
          key: 'action_name'
        },
        {
          key: 'action_type'
        },
        {
          key: 'receipients'
        },
        {
          key: 'forwarded_by'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    closeAssignQuery() {
      this.$emit('closeAssignQuery');
    }
  }
};
