import { format } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'actionTaken',
  components: {
    DatePicker,
    ModelSelect
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      responseMsg: null,
      isSuccess: null,
      showAlert: false,
      loader: false,
      batchName: null,
      scheduleType: {
        value: null,
        text: null
      },
      batchId: 0,
      paymentPriority: null,
      showPartyModal: false,
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentDate: null,
      paymentMode: {
        value: null,
        text: null
      },
      unsubscribe: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      lastUpdatedLogin: null,
      lastUpdatedBy: null,
      dueFromToDate: null,
      payGroup: {
        value: null,
        text: null
      },
      party: {
        value: null,
        text: null
      },
      partyType: null,
      status: 'NEW',
      remarks: null,
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      createdBy: null,
      empDetailsList: [],
      empDetailsFields: [
        {
          key: 'assign_to'
        },
        {
          key: 'Email'
        },
        {
          key: 'department'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      queryAssingList: [],
      queryAssignFields: [
        {
          key: 'assignee'
        },
        {
          key: 'department'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          // this.addEditPaymentMethod();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    addEditPaymentMethod() {
      const payload = {
        due_date_from: commonHelper.formattedDate(this.dueFromToDate[0]),
        due_date_to: commonHelper.formattedDate(this.dueFromToDate[1]),
        le_id: this.leName.value,
        ou_id: this.ouName.value,
        party_id: this.party.value,
        party_type: this.partyType,
        payment_batch_id: this.batchId,
        payment_date: this.paymentDate,
        payment_group_vset: this.payGroup.value,
        payment_method_id: this.paymentMethod.value,
        payment_mode_vset: this.paymentMode.value,
        payment_priority: this.paymentPriority,
        payment_status_vset: this.status,
        remarks: this.remarks,
        schedule_type: this.scheduleType.value
      };
      this.loader = true;
      this.$store
        .dispatch('payable/addEditPaymentBatch', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.batchId = response.data.data.payment_batch_id;
            this.batchName = response.data.data.payment_batch_name;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === 'SCHEDULE_LINE_TYPE_VSET') {
        this.parent_value_set_id = null;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName.text = item.org_name;
        this.leName.value = item.org_id;
        this.ouName.text = null;
        this.ouName.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName.text = item.org_name;
        this.ouName.value = item.org_id;
      } else if (this.vsetCode === 'SCHEDULE_LINE_TYPE_VSET') {
        this.scheduleType.text = item.value_meaning;
        this.scheduleType.value = item.value_code;
      } else if (this.vsetCode === 'PAYMENT_MODE') {
        this.paymentMode.text = item.value_meaning;
        this.paymentMode.value = item.value_code;
      } else if (this.vsetCode === 'PAY_GROUP_TYPE') {
        this.payGroup.text = item.value_meaning;
        this.payGroup.value = item.value_code;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getSelectedPartyCustomer(item) {
      this.party.text = item.party_name;
      this.party.value = item.party_id;
      this.partyType = item.party_type;
      this.showPartyModal = false;
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      this.setCustCode = setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
    },
    closeAssignQuery() {
      this.$emit('closeAssignQuery');
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
