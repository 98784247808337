export default {
  name: 'sentMail',
  components: {},
  props: ['rowDetails'],
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      sentMailData: [],
      sentMailFields: [
        {
          key: 'to'
        }
      ],
      results: null // remove
    };
  },
  mounted() {
    // this.getEmailDetailsById();
  },
  methods: {
    getEmailDetailsById() {
      this.loader = true;
      this.$store
        .dispatch(
          'realManageAdmin/getEmailDetailsByCommId',
          this.rowDetails.comm_id
        )
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.results = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
