import { minLength, required } from 'vuelidate/lib/validators';
import uploadDocument from '../sendEmail/uploadQueryDocument';
export default {
  name: 'sendSms',
  components: { uploadDocument },
  props: ['rowDetails'],
  validations: {
    sender: {
      minLength: minLength(10),
      required
    }
  },
  data() {
    return {
      responseMsg: null,
      isSuccess: null,
      showAlert: false,
      loader: false,
      sender: null,
      comment: null,
      showUploadQueryModal: false
    };
  },
  mounted() {
    if (this.rowDetails) {
      this.sender = this.rowDetails.contact_no;
    }
  },
  methods: {
    showHideUploadQueryModal(flag) {
      this.showUploadQueryModal = flag;
    },
    sendSms() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      // }
    },
    closeAssignQuery() {
      this.$emit('closeAssignQuery');
    }
  }
};
