import { format } from 'date-fns';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'assignQuery',
  props: ['customerQueryDetails'],
  components: {
    DatePicker,
    party,
    ModelSelect
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      responseMsg: null,
      isSuccess: null,
      editMode:false,
      showAlert: false,
      loader: false,
      batchName: null,
      employeeAssigneeType: {
        value: null,
        text: null
      },
      employeeDepartment: {
        value: null,
        text: null
      },
      employeeName: null,
      queryAssigneeType: {
        value: null,
        text: null
      },
      queryDepartment: {
        value: null,
        text: null
      },
      queryName: null,
      scheduleType: {
        value: null,
        text: null
      },
      batchId: 0,
      paymentPriority: null,
      showPartyModal: false,
      paymentMethod: {
        value: null,
        text: null
      },
      paymentMethodList: [],
      paymentDate: null,
      paymentMode: {
        value: null,
        text: null
      },
      unsubscribe: null,
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      lastUpdatedLogin: null,
      lastUpdatedBy: null,
      dueFromToDate: null,
      payGroup: {
        value: null,
        text: null
      },
      party: {
        value: null,
        text: null
      },
      partyType: null,
      status: 'NEW',
      remarks: null,
      creationDate: format(new Date(), appStrings.DATEFNSFORMAT),
      createdBy: null,
      empDetailsList: [],
      empDetailsFields: [
        {
          key: 'assignee_type'
        },
        {
          key: 'assign_to'
        },
        {
          key: 'department'
        },
        {
          key: 'email'
        },
        {
          key: 'primary'
        }
      ],
      queryAssignList: [],
      queryAssignFields: [
        {
          key: 'assignee_type'
        },
        {
          key: 'assignee'
        },
        {
          key: 'department'
        },
        {
          key: 'priority'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  mounted() {
    if (this.customerQueryDetails.query_id) {
      this.getCustomerQueryEmployeeList(this.customerQueryDetails.query_id);
      this.getCustomerQueryAssignedList(this.customerQueryDetails.query_id);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          // this.addEditPaymentMethod();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getCustomerQueryEmployeeList(ccQueryId) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        assignee_type: this.employeeAssigneeType.value,
        department: this.employeeDepartment.value,
        name: this.employeeName,
        ccQueryId: ccQueryId
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getCustomerQueryEmployeeList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.empDetailsList = resp.data.data.page;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCustomerQueryAssignedList(ccQueryId) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        assignee_type: this.queryAssigneeType.value,
        department: this.queryDepartment.value,
        name: this.queryName,
        ccQueryId: ccQueryId
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getCustomerQueryAssignedList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.queryAssignList = resp.data.data.page;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    searchEmployeeAssignQuery() {
      this.getCustomerQueryEmployeeList(this.customerQueryDetails.query_id);
    },
    searchAssignQuery() {
      this.getCustomerQueryAssignedList(this.customerQueryDetails.query_id);
    },
    clearEmployeeAssignQuery() {
      this.employeeAssigneeType.text = null;
      this.employeeAssigneeType.value = null;
      this.employeeDepartment.text = null;
      this.employeeDepartment.value = null;
      this.getCustomerQueryEmployeeList(this.customerQueryDetails.query_id);
    },
    clearAssignQuery() {
      this.queryAssigneeType.text = null;
      this.queryAssigneeType.value = null;
      this.queryDepartment.text = null;
      this.queryDepartment.value = null;
      this.getCustomerQueryAssignedList(this.customerQueryDetails.query_id);
    },
    openValueSetModal(vsetCode, type) {
      this.vsetCode = vsetCode;
      this.type = type;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.ASSIGNEE_TYPE &&
        this.type === 'employee'
      ) {
        this.employeeAssigneeType.value = item.value_code;
        this.employeeAssigneeType.text = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DEPARTMENT &&
        this.type === 'employee'
      ) {
        this.employeeDepartment.value = item.value_code;
        this.employeeDepartment.text = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.ASSIGNEE_TYPE &&
        this.type === 'query'
      ) {
        this.queryAssigneeType.value = item.value_code;
        this.queryAssigneeType.text = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DEPARTMENT &&
        this.type === 'query'
      ) {
        this.queryDepartment.value = item.value_code;
        this.queryDepartment.text = item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    // getSelectedPartyCustomer(item) {
    //   this.party.text = item.party_name;
    //   this.party.value = item.party_id;
    //   this.partyType = item.party_type;
    //   this.showPartyModal = false;
    // },
    // showHidePartyModal(flag) {
    //   this.showPartyModal = flag;
    //   this.setCustCode = setTimeout(() => {
    //     this.eventBus.$emit('userType', 'CUST');
    //   }, 0);
    // },
    closeAssignQuery() {
      this.$emit('closeAssignQuery');
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
