import URL_UTILITY from '@/app/utility/url.utility';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'uploadQueryDocument',
  components: {},
  props: ['emailDetails', 'saveDraft', 'isDraft', 'uploadType'],
  data() {
    return {
      loader: false,
      showAlert: false,
      downloadDocUrl: URL_UTILITY.downloadQueryDocumentDocUrl,
      isSuccess: false,
      responseMsg: '',
      commTypeVset: null,
      selectedFilesArr: [],
      uploadDocList: [],
      uploadDocFields: [
        {
          key: 'file_name'
        },
        {
          key: 'file_size'
        },
        {
          key: 'delete',
          stickyColumn: true
        }
      ],
      specIndex: null
    };
  },
  mounted() {
    if (this.uploadType === 'email') {
      this.commTypeVset = 'SENDGRID';
      this.getUploadedDocDtl();
    } else {
      this.commTypeVset = 'WSMS';
    }
  },
  methods: {
    saveEmailInTempTable() {
      const payload = {
        menu_id: this.$store.state.shared.menuId,
        comm_id: this.isDraft ? this.saveDraft.commId : 0,
        comm_type_vset: this.commTypeVset,
        query_id: this.saveDraft.query_id,
        to_email: this.saveDraft.toEmail,
        cc_email: this.saveDraft.ccEmail,
        bcc_email: this.saveDraft.bccEmail,
        subject: this.saveDraft.subject,
        body: this.saveDraft.body,
        is_draft: true
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/saveEmailInTempTable', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.saveDraft.commId = response.data.data.comm_id;
            this.uploadFile();
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    browseFile() {
      if (this.uploadDocList.length === 0) {
        this.uploadDocList = [];
      }
      this.$refs.file.click();
    },
    selectFile() {
      this.selectedFilesArr.push(this.$refs.file.files);
      if (this.$refs.file.files[0].size <= 5242880) {
        this.uploadDocList.push({
          fileRef: this.$refs.file.files,
          file_name: this.$refs.file.files[0].name,
          file_size: this.formatBytes(this.$refs.file.files[0].size),
          docDetailId: 0
        });
      } else {
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg =
          this.$refs.file.files[0].name + ' file should be less than 5 MB.';
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    checkUpload() {
      if (this.isDraft) {
        this.uploadFile();
      } else {
        this.saveEmailInTempTable();
      }
    },
    uploadFile() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('doc_detail_id', 0);
          formData.append('comm_id', this.saveDraft.commId);
          formData.append('query_id', this.emailDetails.query_id);
          formData.append('delete_flag', false);
          this.loader = true;
          this.$store
            .dispatch('realManageAdmin/addUploadDocument', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 201) {
                this.getUploadedDocDtl();
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
              } else {
                this.isSuccess = false;
                this.responseMsg =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    getUploadedDocDtl() {
      const payload = {
        queryId: this.emailDetails.query_id
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/getUploadDocumentQuery', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.length > 0) {
              const docList = response.data.data.map(data => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  file_size: this.formatBytes(data.file_size),
                  docDetailId: data.doc_dtl_id,
                  commId: data.comm_id,
                  queryId: data.query_id
                };
              });
              this.uploadDocList = docList;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteUploadDocument() {
      const payload = {
        query_id: this.uploadDocList[this.specIndex].queryId,
        comm_id: this.uploadDocList[this.specIndex].commId,
        doc_dtl_id: this.uploadDocList[this.specIndex].docDetailId
      };
      this.loader = true;
      this.$store
        .dispatch('realManageAdmin/deleteUploadDocument', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getUploadedDocDtl();
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteRow(index) {
      this.specIndex = index;
      if (this.uploadDocList[index].docDetailId > 0) {
        const promt = confirm('Are you sure, you want to delete?');
        if (promt) {
          this.deleteUploadDocument();
        }
      } else {
        this.uploadDocList.splice(index, 1);
      }
    }
  }
};
